import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["editButtonText", "saveButton", "viewMode", "editMode", "form"]
    static values = { editMode: Boolean }

    connect() {
        if (this.hasFormTarget) {
            this.formTarget.addEventListener("turbo:submit-end", this.handleSubmitEnd.bind(this))
        }
        this.updateEditMode(this.editModeValue)
    }

    toggleEditMode(event) {
        event.preventDefault()
        this.editModeValue = !this.editModeValue
    }

    updateEditMode(isEditMode) {
        this.viewModeTargets.forEach(el => el.classList.toggle("hidden", isEditMode))
        this.editModeTargets.forEach(el => el.classList.toggle("hidden", !isEditMode))
        this.saveButtonTarget.classList.toggle("hidden", !isEditMode)
        this.editButtonTextTarget.textContent = isEditMode ? "Cancel" : "Edit"
    }

    editModeValueChanged(value) {
        this.updateEditMode(value)
    }

    handleSubmitEnd(event) {
        const fetchResponse = event.detail.fetchResponse

        if (fetchResponse.response.redirected) {
            // Successful update, redirect happened
            window.location = fetchResponse.response.url
        } else {
            // The form will be replaced with the updated version
            // Edit mode will be set based on whether there are errors
        }
    }
}