import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'todaysCoreAtomCountBadge',
        'upcomingCoreAtomCountBadge',
        'todaysReviewAtomCountBadge',
        'upcomingReviewAtomCountBadge',
        'todaysBitsAtomCountBadge',
        'upcomingBitsAtomCountBadge',
        'todaysSongsAtomCountBadge',
        'upcomingSongsAtomCountBadge',
        'todaysVideosAtomCountBadge',
        'upcomingVideosAtomCountBadge'
        ]

    connect() {
        this.boundFetchCounts = event => this.fetchCounts(event);
        this.boundFetchCounts();
        this.element.addEventListener('turbo:load', event => this.fetchCounts(event));
        this.element.addEventListener('turbo:submit-end', event => this.fetchCounts(event));
        this.element.addEventListener('turbo:frame-load', event => this.fetchCounts(event));
    }

    disconnect() {
        this.element.removeEventListener('turbo:frame-load', this.boundFetchCounts);
        this.element.removeEventListener('turbo:frame', this.boundFetchCounts);
        this.element.removeEventListener('turbo:submit-end', this.boundFetchCounts);
    }

    fetchCounts(event) {
        console.log('fetchCounts was called by:', event);
        fetch('/api/atom_count/atom_counts')
            .then(response => response.json())
            .then(data => {
                if (data) {
                    this.todaysCoreAtomCountBadgeTarget && this.updateBadge(this.todaysCoreAtomCountBadgeTarget, data.todays_core_atom_count);
                    this.upcomingCoreAtomCountBadgeTarget && this.updateBadge(this.upcomingCoreAtomCountBadgeTarget, data.upcoming_core_atom_count);
                    this.todaysReviewAtomCountBadgeTarget && this.updateBadge(this.todaysReviewAtomCountBadgeTarget, data.todays_review_atom_count);
                    this.upcomingReviewAtomCountBadgeTarget && this.updateBadge(this.upcomingReviewAtomCountBadgeTarget, data.upcoming_review_atom_count);
                    this.todaysBitsAtomCountBadgeTarget && this.updateBadge(this.todaysBitsAtomCountBadgeTarget, data.todays_bit_atom_count);
                    this.upcomingBitsAtomCountBadgeTarget && this.updateBadge(this.upcomingBitsAtomCountBadgeTarget, data.upcoming_bit_atom_count);
                    this.todaysSongsAtomCountBadgeTarget && this.updateBadge(this.todaysSongsAtomCountBadgeTarget, data.todays_song_atom_count);
                    this.upcomingSongsAtomCountBadgeTarget && this.updateBadge(this.upcomingSongsAtomCountBadgeTarget, data.upcoming_song_atom_count);
                    this.todaysVideosAtomCountBadgeTarget && this.updateBadge(this.todaysVideosAtomCountBadgeTarget, data.todays_video_atom_count);
                    this.upcomingVideosAtomCountBadgeTarget && this.updateBadge(this.upcomingVideosAtomCountBadgeTarget, data.upcoming_video_atom_count);
                } else {
                    console.warn('The data retrieved is null or undefined');
                }

            })
            .catch(error => {
                console.error('An error occurred while fetching atom counts:', error);
            })
    }

    updateBadge(badgeElement, value) {
        if (badgeElement) {
            badgeElement.textContent = value;
            badgeElement.classList.toggle("hidden", value === 0);
        } else {
            console.warn('Target element is not available');
        }
    }
}