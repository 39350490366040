import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = ["button"]
    static values = {
        atomId: Number,
        isRecording: Boolean,
        startPath: String,
        stopPath: String,
        autoStart: Boolean
    }

    connect() {
        super.connect()
        this.updateButtonState()
        document.addEventListener('recording-started', this.handleGlobalRecordingStarted.bind(this))
        document.addEventListener('recording-stopped', this.handleGlobalRecordingStopped.bind(this))
        window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this))

        if (this.autoStartValue) {
            this.startRecording(false) // Start recording without user interaction, only if autoStart is true
        }
    }

    disconnect() {
        if (this.isRecordingValue) {
            this.stopRecording(false) // Stop recording without user interaction
        }
        document.removeEventListener('recording-started', this.handleGlobalRecordingStarted.bind(this))
        document.removeEventListener('recording-stopped', this.handleGlobalRecordingStopped.bind(this))
        window.removeEventListener('beforeunload', this.handleBeforeUnload.bind(this))
        super.disconnect()
    }

    toggleRecording() {
        if (this.isRecordingValue) {
            this.stopRecording(true)
        } else {
            this.startRecording(true)
        }
    }

    startRecording(isUserInitiated = true) {
        this.log(`Starting recording for atom: ${this.atomIdValue}`)
        const csrfToken = this.getCsrfToken()
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        if (csrfToken) {
            headers['X-CSRF-Token'] = csrfToken
        }

        fetch(this.startPathValue, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                currently_recording_atom_id: this.getCurrentlyRecordingAtomId()
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then(data => {
                this.log("Recording started successfully")
                this.isRecordingValue = true
                this.updateButtonState()
                if (isUserInitiated) {
                    document.dispatchEvent(new CustomEvent('recording-started', { detail: { atomId: this.atomIdValue } }))
                }
            })
            .catch(error => {
                this.log("Error starting recording: " + error)
            })
    }

    stopRecording(isUserInitiated = true) {
        this.log(`Stopping recording for atom: ${this.atomIdValue}`)
        const csrfToken = this.getCsrfToken()
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        if (csrfToken) {
            headers['X-CSRF-Token'] = csrfToken
        }

        fetch(this.stopPathValue, {
            method: 'POST',
            headers: headers
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then(data => {
                this.log("Recording stopped successfully")
                this.isRecordingValue = false
                this.updateButtonState()
                if (isUserInitiated) {
                    document.dispatchEvent(new CustomEvent('recording-stopped', { detail: { atomId: this.atomIdValue } }))
                }
            })
            .catch(error => {
                this.log("Error stopping recording: " + error)
            })
    }

    updateButtonState() {
        if (this.isRecordingValue) {
            this.buttonTarget.classList.add('recording')
            this.buttonTarget.classList.remove('not-recording')
        } else {
            this.buttonTarget.classList.add('not-recording')
            this.buttonTarget.classList.remove('recording')
        }
    }

    handleGlobalRecordingStarted(event) {
        if (event.detail.atomId !== this.atomIdValue) {
            this.isRecordingValue = false
            this.updateButtonState()
        }
    }

    handleGlobalRecordingStopped(event) {
        if (event.detail.atomId === this.atomIdValue) {
            this.isRecordingValue = false
            this.updateButtonState()
        }
    }

    handleBeforeUnload() {
        if (this.isRecordingValue) {
            this.stopRecording(false)
        }
    }

    getCsrfToken() {
        const metaTag = document.querySelector('meta[name="csrf-token"]')
        return metaTag ? metaTag.content : null
    }

    getCurrentlyRecordingAtomId() {
        const recordingElement = document.querySelector('[data-recording-button-is-recording-value="true"]')
        return recordingElement ? recordingElement.dataset.recordingButtonAtomIdValue : null
    }

    log(message) {
        console.log(`[RecordingButton] ${message}`)
    }
}