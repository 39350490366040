/* eslint no-console:0 */

import "@hotwired/turbo-rails"

require("@rails/activestorage").start()
require("local-time").start()

import "./channels"
import "./controllers"
import "./src/**/*"
import "./media_player/**/*"
import "./atomised_guitar/**/*"
// channels
import ApexCharts from 'apexcharts'



// Import event handler
import {handleTurboEvent} from './debugging/dom_and_turbo_event_loggers';

// List of Turbo events to listen for
const turboEvents = [
    "turbo:visit",
    "turbo:before-visit",
    "turbo:fetch-response",
    "turbo:before-fetch-response",
    "turbo:submit-end",
    "turbo:frame-render",
    "turbo:stream-render",
    "turbo:frame-load"
];

// Add event listeners
turboEvents.forEach(eventName => {
    document.addEventListener(eventName, handleTurboEvent);
});

document.addEventListener("turbo:load", function() {
    var chartElements = document.querySelectorAll("[data-apexchart]")
    chartElements.forEach(function(element) {
        var options = JSON.parse(element.dataset.options)

        // Replace the placeholder with the actual formatter function
        if (options.dataLabels && options.dataLabels.formatter === 'FORMATTER_PLACEHOLDER') {
            options.dataLabels.formatter = function(val) {
                return val.toFixed(0) + " min";  // Or whatever formatting you want
            };
        }

        var chart = new ApexCharts(element, options)
        chart.render()
    })
})
