import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = [
        "atomSoundFilePlayerHideShow",
        "atomNotesHideShow",
        "atomVideoHideShow",
        "atomSoundFilePlayerButton",
        "atomNotesButton",
        "atomVideoButton"
    ];

    connect() {
        super.connect()
        this.hideAllMutuallyExclusiveTargets();
        this.deactivateAllButtons();
        this.log("Initial state set: all targets hidden and buttons deactivated", "debug")
    }

    get buttons() {
        return ["atomSoundFilePlayerButton", "atomNotesButton", "atomVideoButton"]
            .filter(button => this.hasTarget(button))
            .map(button => this[`${button}Target`]);
    }

    get mutuallyExclusiveTargets() {
        return ["atomSoundFilePlayerHideShow", "atomNotesHideShow", "atomVideoHideShow"]
            .filter(target => this.hasTarget(target));
    }

    atomNotesHideShow() {
        this.log("Toggling Notes visibility", "debug")
        this.toggleActiveState("atomNotesHideShow", "atomNotesButton");
    }

    atomSoundFilePlayerHideShow() {
        this.log("Toggling Sound File Player visibility", "debug")
        this.toggleActiveState("atomSoundFilePlayerHideShow", "atomSoundFilePlayerButton");
    }

    atomVideoHideShow() {
        this.log("Toggling Video visibility", "debug")
        this.toggleActiveState("atomVideoHideShow", "atomVideoButton");
    }

    hideAllMutuallyExclusiveTargets() {
        this.log("Hiding all mutually exclusive targets", "debug")
        this.mutuallyExclusiveTargets.forEach((target) => {
            this[`${target}Target`].classList.add("hidden");
            this.log(`Hidden target: ${target}`, "debug")
        });
    }

    toggleActiveState(targetName, buttonName) {
        this.log(`Toggling active state for ${targetName}`, "debug")

        if (!this.hasTarget(targetName) || !this.hasTarget(buttonName)) {
            this.log(`Target or button not found: ${targetName} or ${buttonName}`, "warn")
            return;
        }

        const target = this[`${targetName}Target`];
        const button = this[`${buttonName}Target`];

        this.deactivateAllButtons();

        if (!target.classList.contains("hidden")) {
            this.log(`${targetName} is visible, hiding all targets`, "debug")
            this.hideAllMutuallyExclusiveTargets();
        } else {
            this.log(`${targetName} is hidden, making it visible`, "debug")
            this.hideAllMutuallyExclusiveTargets();
            target.classList.remove("hidden");
            button.classList.add("active");
            this.log(`Made ${targetName} visible and activated ${buttonName}`, "info")
        }
    }

    deactivateAllButtons() {
        this.buttons.forEach(btn => {
            btn.classList.remove("active")
            this.log(`Deactivated button: ${btn.id}`, "debug")
        });
    }

    hasTarget(name) {
        return this[`has${name.charAt(0).toUpperCase() + name.slice(1)}Target`];
    }
}