// loading_animations_dropdown_change_controller.js
import BaseController from "../utils/base_controller"

/**
 * LoadingAnimationsDropdownChange Controller
 *
 * This Stimulus controller manages loading animations for dropdown changes
 * and form submissions, typically used with Turbo Frames.
 *
 * Features:
 * 1. Handles dropdown changes by automatically submitting the form
 * 2. Shows a spinner when the form is submitted
 * 3. Hides the spinner when the Turbo Frame is rendered
 * 4. Provides debug logging
 *
 * Usage:
 * <div data-controller="loading-animations--dropdown-change"
 *      data-loading-animations--dropdown-change-debug-value="false"
 *      data-loading-animations--dropdown-change-spinner-id-value="my-spinner"
 *      data-loading-animations--dropdown-change-frame-id-value="my-turbo-frame">
 *   <form data-action="submit->loading-animations--dropdown-change#handleSubmit">
 *     <select data-action="change->loading-animations--dropdown-change#handleChange">
 *       <option value="1">Option 1</option>
 *       <option value="2">Option 2</option>
 *     </select>
 *   </form>
 * </div>
 * <div id="my-spinner" class="hidden">Loading...</div>
 * <turbo-frame id="my-turbo-frame"></turbo-frame>
 *
 * Note: Ensure you have a spinner element and a Turbo Frame in your HTML
 * with the specified IDs. The spinner should have a 'hidden' class that
 * can be toggled to show/hide it.
 */
export default class LoadingAnimationsDropdownChange extends BaseController {
    static values = {
        spinnerId: String,
        frameId: String
    }

    connect() {
        super.connect()
        this.log("Dropdown Change Controller connected")
        this.log(`Spinner ID: ${this.spinnerIdValue}`)
        this.log(`Frame ID: ${this.frameIdValue}`)

        this.bindEvents()
    }

    disconnect() {
        this.unbindEvents()
        this.log("Dropdown Change Controller disconnected")
        super.disconnect()
    }

    bindEvents() {
        this.handleFrameRender = this.handleFrameRender.bind(this)
    }

    unbindEvents() {
        const frame = document.getElementById(this.frameIdValue)
        if (frame) {
            frame.removeEventListener('turbo:frame-render', this.handleFrameRender)
        }
    }

    handleChange(event) {
        this.log('Dropdown value changed, submitting form')
        event.target.form.requestSubmit()
    }

    handleSubmit(event) {
        this.log('Form submitted, showing spinner')
        this.showSpinner()

        const frame = document.getElementById(this.frameIdValue)
        if (frame) {
            frame.addEventListener('turbo:frame-render', this.handleFrameRender, { once: true })
        } else {
            this.log(`Frame with id '${this.frameIdValue}' not found`, "warn")
        }

        // We don't prevent default here, allowing the form to submit normally
    }

    handleFrameRender(event) {
        this.log('Frame rendered, hiding spinner')
        this.hideSpinner()
    }

    showSpinner() {
        this.log('showSpinner called')
        const spinner = this.getSpinnerElement()
        if (spinner) {
            spinner.classList.remove('hidden')
            this.log("Spinner shown")
        } else {
            this.log(`Spinner with id '${this.spinnerIdValue}' not found`, "error")
        }
    }

    hideSpinner() {
        this.log('hideSpinner called')
        const spinner = this.getSpinnerElement()
        if (spinner) {
            spinner.classList.add('hidden')
            this.log("Spinner hidden")
        } else {
            this.log(`Spinner with id '${this.spinnerIdValue}' not found`, "error")
        }
    }

    getSpinnerElement() {
        if (!this.spinnerIdValue) {
            this.log("Spinner ID is not set", "error")
            return null
        }
        const spinner = document.getElementById(this.spinnerIdValue)
        if (!spinner) {
            this.log(`Spinner element with id '${this.spinnerIdValue}' not found in the DOM`, "error")
        }
        return spinner
    }
}