import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = ["songImages"]

    connect() {
        super.connect()
        this.log(`Initial layout: ${this.currentLayout}`, "debug")
    }

    disconnect() {
        this.log("Toggle Row Column Layout controller disconnected", "debug")
        super.disconnect()
    }

    toggleRowColumnLayout() {
        const songImages = this.songImagesTarget

        if (songImages.classList.contains('flex-row')) {
            songImages.classList.remove('flex-row')
            songImages.classList.add('flex-col')
            this.log("Layout changed to column", "debug")
        } else {
            songImages.classList.remove('flex-col')
            songImages.classList.add('flex-row')
            this.log("Layout changed to row", "debug")
        }

        this.log(`New layout: ${this.currentLayout}`, "debug")
    }

    get currentLayout() {
        return this.songImagesTarget.classList.contains('flex-row') ? 'row' : 'column'
    }
}