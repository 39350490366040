// songs_atom_count_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['todaysVideosAtomCountBadge', 'upcomingVideosAtomCountBadge']

    connect() {
        this.fetchCounts()
    }

    fetchCounts() {
        fetch('/api/atom_count/videos_atom_counts')
            .then(response => response.json())
            .then(data => {
                this.updateBadge(this.todaysVideosAtomCountBadgeTarget, data.todays_video_atom_count)
                this.updateBadge(this.upcomingVideosAtomCountBadgeTarget, data.upcoming_video_atom_count)
            })
            .catch(error => console.error('Error fetching song atom counts:', error))
    }

    updateBadge(badgeElement, value) {
        badgeElement.textContent = value
        badgeElement.classList.toggle("hidden", value === 0)
    }
}
