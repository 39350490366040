// songs_atom_count_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['todaysSongsAtomCountBadge', 'upcomingSongsAtomCountBadge']

    connect() {
        this.fetchCounts()
    }

    fetchCounts() {
        fetch('/api/atom_count/songs_atom_counts')
            .then(response => response.json())
            .then(data => {
                this.updateBadge(this.todaysSongsAtomCountBadgeTarget, data.todays_song_atom_count)
                this.updateBadge(this.upcomingSongsAtomCountBadgeTarget, data.upcoming_song_atom_count)
            })
            .catch(error => console.error('Error fetching song atom counts:', error))
    }

    updateBadge(badgeElement, value) {
        badgeElement.textContent = value
        badgeElement.classList.toggle("hidden", value === 0)
    }
}
