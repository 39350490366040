// core_atom_count_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['todaysCoreAtomCountBadge', 'upcomingCoreAtomCountBadge']

    connect() {
        this.fetchCounts()
    }

    fetchCounts() {
        fetch('/api/atom_count/core_atom_counts')
            .then(response => response.json())
            .then(data => {
                this.updateBadge(this.todaysCoreAtomCountBadgeTarget, data.todays_core_atom_count)
                this.updateBadge(this.upcomingCoreAtomCountBadgeTarget, data.upcoming_core_atom_count)
            })
            .catch(error => console.error('Error fetching core atom counts:', error))
    }

    updateBadge(badgeElement, value) {
        badgeElement.textContent = value
        badgeElement.classList.toggle("hidden", value === 0)
    }
}