import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = ["layoutClass"]
    static values = {
        columnsLayout: Number
    }

    connect() {
        super.connect()
        this.log(`Initial columns layout value: ${this.columnsLayoutValue}`, "debug")
        this.setColumns()
    }

    disconnect() {
        // Perform any necessary cleanup here
        super.disconnect()
    }

    setColumns(event) {
        if (event && event.currentTarget.hasAttribute('data-columns-layout-columns-value')) {
            const newValue = event.currentTarget.getAttribute('data-columns-layout-columns-value')
            this.log(`Updating columns layout value from ${this.columnsLayoutValue} to ${newValue}`, "debug")
            this.columnsLayoutValue = parseInt(newValue, 10)
        }

        this.log(`Setting columns to: ${this.columnsLayoutValue}`)

        const classesToRemove = ["grid-cols-1", "grid-cols-2", "grid-cols-3", "grid-cols-4"]
        this.layoutClassTargets.forEach((element, index) => {
            const oldClasses = [...element.classList].filter(cls => classesToRemove.includes(cls))
            element.classList.remove(...classesToRemove)
            element.classList.add(`grid-cols-${this.columnsLayoutValue}`)

            this.log(`Updated element ${index}: removed [${oldClasses.join(', ')}], added [grid-cols-${this.columnsLayoutValue}]`, "debug")
        })

        this.log(`Column layout update complete. Current layout: ${this.columnsLayoutValue} columns`, "info")
    }
}