// Hide Show Tools Controller
//
// This controller manages the visibility of various tools in the application,
// such as setting options, metronome timer, guitar tuner, and artificial intelligence.
// It handles toggling the visibility of tools and manages their active states.

import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = [ "settingOptions", "metronomeTimer", "guitarTuner", "artificialIntelligence" ]

    connect() {
        super.connect()
        this.activeTools = new Set()
        this.log(`Initial active tools: ${Array.from(this.activeTools).join(', ')}`, "debug")
    }

    disconnect() {
        this.log(`Final active tools: ${Array.from(this.activeTools).join(', ')}`, "debug")
        super.disconnect()
    }

    toggleSettingOptions() {
        this.log("Toggling Setting Options", "debug")
        this.toggleTool("settings", this.settingOptionsTarget)
    }

    toggleMetronomeTimer() {
        this.log("Toggling Metronome Timer", "debug")
        this.toggleTool("metronome", this.metronomeTimerTarget)
    }

    toggleArtificialIntelligence() {
        this.log("Toggling Artificial Intelligence", "debug")
        this.toggleTool("ai", this.artificialIntelligenceTarget)
    }

    toggleGuitarTuner() {
        this.log("Toggling Guitar Tuner", "debug")
        this.toggleTool("tuner", this.guitarTunerTarget)
    }

    toggleTool(toolName, target) {
        const isHidden = target.classList.toggle('hidden')
        this.log(`${toolName} visibility toggled. Is now hidden: ${isHidden}`, "debug")

        this.toggleActiveIcon(toolName)

        if (isHidden) {
            this.activeTools.delete(toolName)
            this.log(`${toolName} removed from active tools`, "debug")
        } else {
            this.activeTools.add(toolName)
            this.log(`${toolName} added to active tools`, "debug")
        }

        this.log(`Current active tools: ${Array.from(this.activeTools).join(', ')}`, "info")
    }

    toggleActiveIcon(toolName) {
        const button = this.element.querySelector(`[data-tool-name="${toolName}"]`)
        if (!button) {
            this.log(`Button for ${toolName} not found`, "warn")
            return
        }

        const regularIcon = button.querySelector('.tool-icon')
        const activeIcon = button.querySelector('.tool-icon-active')

        if (regularIcon && activeIcon) {
            regularIcon.classList.toggle('hidden')
            activeIcon.classList.toggle('hidden')
            this.log(`Icons toggled for ${toolName}`, "debug")
        } else {
            this.log(`Icons for ${toolName} not found or incomplete`, "warn")
        }
    }
}