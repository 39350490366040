// review_atom_count_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['todaysReviewAtomCountBadge', 'upcomingReviewAtomCountBadge']

    connect() {
        this.fetchCounts()
    }

    fetchCounts() {
        fetch('/api/atom_count/review_atom_counts')
            .then(response => response.json())
            .then(data => {
                this.updateBadge(this.todaysReviewAtomCountBadgeTarget, data.todays_review_atom_count)
                this.updateBadge(this.upcomingReviewAtomCountBadgeTarget, data.upcoming_review_atom_count)
            })
            .catch(error => console.error('Error fetching review atom counts:', error))
    }

    updateBadge(badgeElement, value) {
        console.log('badgeElement :' + badgeElement)
        badgeElement.textContent = value
        badgeElement.classList.toggle("hidden", value === 0)
    }
}
