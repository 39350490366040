/**
 * AnimateRemoveController
 *
 * This Stimulus controller manages the animation and removal of scheduled items.
 * It handles the unscheduling process, including server communication and DOM manipulation.
 *
 * It is designed to avoid the situation where fast unscheduling doesn't keep up and can result in attempts
 * to unschedule already unscheduled creating a previously unscheduled record.
 *
 * Key features:
 * - Sends unschedule requests to the server
 * - Animates the removal of items from the DOM
 * - Supports both JSON and Turbo Stream responses
 * - Provides debug logging for development
 *
 * Usage:
 * Add data-controller="animate-remove" to the parent element and
 * data-action="click->animate-remove#remove" to the unschedule button.
 */

import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static values = {
        animateOut: String,
        unscheduleUrl: String,
        animationDuration: Number
    }

    static targets = ["unscheduleButton"]

    alreadyUnscheduledCount = 0
    processingItems = new Set()

    connect() {
        super.connect()
        this.log(`Configuration: animateOut=${this.animateOutValue}, unscheduleUrl=${this.unscheduleUrlValue}, animationDuration=${this.animationDurationValue}`, "debug")
        this.resetUnscheduledCount()
    }

    remove(event) {
        event.preventDefault()

        const button = event.currentTarget
        const itemId = button.dataset.id
        const targetFrame = button.closest("[id^='schedule-nav-card-frame-']")

        if (!targetFrame) {
            this.log("Target frame not found", "warn")
            return
        }

        this.processRemoval(itemId, targetFrame)
    }

    async processRemoval(id, element) {
        const startTime = performance.now();

        if (this.processingItems.has(id)) {
            this.log(`Item ${id} is already being processed`, "warn")
            return;
        }

        this.processingItems.add(id);
        this.log(`Processing removal for item ${id}`)

        try {
            const url = this.unscheduleUrlValue.replace(':id', id);

            let response;
            try {
                response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                        'Accept': 'application/json, text/vnd.turbo-stream.html',
                        'Content-Type': 'application/json'
                    }
                });
            } catch (fetchError) {
                this.log(`Fetch error for item ${id}: ${fetchError}`, "error")
                this.handleSuccessfulUnschedule(element);
                return;
            }

            if (!response.ok) {
                const errorText = await response.text();
                if (response.status === 404) {
                    this.alreadyUnscheduledCount++;
                    this.log(`Item ${id} was already unscheduled. Count: ${this.alreadyUnscheduledCount}`, "warn")
                } else {
                    this.log(`Unschedule request for item ${id} failed. Status: ${response.status}, Message: ${errorText}`, "error")
                }
                this.handleSuccessfulUnschedule(element);
                return;
            }

            const contentType = response.headers.get("Content-Type");
            if (contentType && contentType.includes("application/json")) {
                const result = await response.json();
                this.log(`Received JSON response for item ${id}`, "debug")
                this.handleSuccessfulUnschedule(element);
            } else if (contentType && contentType.includes("text/vnd.turbo-stream.html")) {
                const html = await response.text();
                this.log(`Received Turbo Stream response for item ${id}`, "debug")
                Turbo.renderStreamMessage(html);
            } else {
                this.log(`Received unexpected content type for item ${id}: ${contentType}`, "warn")
                this.handleSuccessfulUnschedule(element);
            }
        } catch (error) {
            this.log(`Error processing removal for item ${id}: ${error}`, "error")
            this.handleSuccessfulUnschedule(element);
        } finally {
            this.processingItems.delete(id);
            const endTime = performance.now();
            this.log(`Removal process for item ${id} took ${endTime - startTime}ms`, "debug")
        }
    }

    resetUnscheduledCount() {
        this.alreadyUnscheduledCount = 0;
        this.log("Reset unscheduled count", "debug")
    }

    handleSuccessfulUnschedule(element) {
        this.log(`Handling successful unschedule for element ${element.id}`)
        this.animateOut(element)
        element.addEventListener('animationend', () => {
            this.log(`Animation ended for item ${element.id}, removing from DOM`)
            element.remove()
        }, { once: true })
    }

    animateOut(element) {
        this.log(`Starting animation for element ${element.id}`)
        element.classList.add(this.animateOutValue)
    }
}